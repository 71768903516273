









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { Brand } from '@/components/SAdmin/BrandItem.vue'

@Component
export default class DeactivateBrandPopup extends Vue {
  @Prop() popupId!: string
  @Prop() storeDetails!: any

  deleteLocation () {
    this.$http
      .delete('staff/' + this.storeDetails.id, {
        headers: {
          'user-type': 'sadmin'
        }
      })
      .then((response) => {
        this.$emit('refreshStoreLocationsList')
        let DeleteLocationCloseButtonPopup: HTMLElement = this.$refs.DeleteLocationCloseButtonPopup as HTMLElement
        this.$emit('StaffDeleted')
        DeleteLocationCloseButtonPopup.click()
      })
  }
}
