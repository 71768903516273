var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-12 col-md-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.storeSearchQuery,
                expression: "storeSearchQuery"
              }
            ],
            staticClass: "search-box",
            attrs: { type: "search", q: "store", placeholder: "Search Staff" },
            domProps: { value: _vm.storeSearchQuery },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.storeSearchQuery = $event.target.value
              }
            }
          })
        ]),
        _c("div", { staticClass: "col-xs-12 col-md-8 sort-control" }, [
          _c("div", { staticClass: "filters" }, [
            _vm._v("\n        Sort By\n        "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.sort,
                    expression: "sort"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.sort = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { value: "id-DESC", selected: "" } }, [
                  _vm._v("Created on DESC")
                ]),
                _c("option", { attrs: { value: "id-ASC" } }, [
                  _vm._v("created On ASC")
                ]),
                _c("option", { attrs: { value: "name-ASC" } }, [_vm._v("A-Z")]),
                _c("option", { attrs: { value: "name-DESC" } }, [
                  _vm._v("Z-A")
                ]),
                _c("option", { attrs: { value: "deleted_at-DESC" } }, [
                  _vm._v("Deleted Stores")
                ])
              ]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.staff, function(s) {
          return _vm.staff.length > 0
            ? _c(
                "div",
                { key: s.id, staticClass: "col-xs-12 col-sm-6 item-list" },
                [
                  _c("StaffItem", {
                    attrs: { staff: s },
                    on: { getStaffDetails: _vm.getStaffDetails }
                  })
                ],
                1
              )
            : _vm._e()
        }),
        0
      ),
      _vm.staff.length == 0 && !_vm.loadingStaff
        ? _c("h2", { staticStyle: { "text-align": "center" } }, [
            _vm._v("No Staff Found in system!")
          ])
        : _vm._e(),
      _vm.loadingStaff
        ? _c("h2", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Loading Staff....")
          ])
        : _vm._e(),
      _vm.hasMoreStaff && !_vm.loadingStaff
        ? _c(
            "div",
            {
              staticClass: "textgetStaffDetails-center",
              on: { click: _vm.getStaff }
            },
            [
              _c("button", { staticClass: "btn-positive" }, [
                _vm._v("Load more")
              ])
            ]
          )
        : _vm._e(),
      _c("StaffPopup", {
        attrs: {
          editStaffFlag: false,
          popupId: "staffpopup",
          closeStaffEditPopup: _vm.closeStaffEditPopup
        },
        on: { refreshStaffList: _vm.reFilter }
      }),
      _c("StaffPopup", {
        attrs: {
          staffDetails: _vm.staffDetails,
          editStaffFlag: true,
          popupId: "editstaffpopup",
          closeStaffEditPopup: _vm.closeStaffEditPopup
        },
        on: { refreshStaffList: _vm.reFilter }
      }),
      _c("DeleteStaffPopup", {
        attrs: {
          staff: _vm.staff,
          storeDetails: _vm.staffDetails,
          popupId: "deletestaffpopup"
        },
        on: { StaffDeleted: _vm.staffDeleted }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }