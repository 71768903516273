<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>
            <img src="/img/Alerts/plusbig_icon.png"> Staff
          </h2>
          <button
            ref="popupCloseButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="addStaff" autocomplete="off" data-vv-scope="add-staff">
            <input
              type="text"
              name="name"
              v-model="staff.name"
              placeholder="Staff Name"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('add-staff.name') }"
            >
             <input
              type="email"
              name="email"
              v-model="staff.email"
              placeholder="Staff Email"
              v-validate="'required'"
              :class="{'input': true, 'is-danger': errors.has('add-staff.email') }"
            >
            <div class="row" v-if="!editStaffFlag">
              <div class="col-xs-6 col-sm-6">
                <select v-model="country_isd_code" name="country_id" :class="{'input': true, 'is-danger': errors.has('add-staff.country_id') }">
                  <option
                    v-for="country in countries"
                    :key="country.id"
                    :value="country.id"
                  >{{country.isd_code}}({{country.name}})</option>
                </select>
              </div>
              <div class="col-xs-6 col-sm-6">
                <the-mask mask="### ###-####"
                  type="tel"
                  autocomplete="mobile"
                  placeholder="Mobile Number"
                  v-model="staff.mobile_number"
                  name="mobile"
                  v-validate="{ required: true, regex: /^\d{3} ?\d{3}-?\d{4}$/ }"
                  :class="{'input': true, 'is-danger': errors.has('add-staff.mobile') }"
                  ></the-mask>
              </div>
            </div>
            <input
              type="password"
              name="password"
              v-model="staff.password"
              placeholder="Password"
              v-validate="{required: this.isPasswordRequired, min: 8}"
              :class="{'input': true, 'is-danger': errors.has('add-staff.password') }"
            >

            <div class="row">
              <div class="col-xs-12 col-sm-6" v-if="editStaffFlag">
                <button
                  type="button"
                  class="btn-lg-grey"
                  data-toggle="modal"
                  data-target="#deletestaffpopup"
                  @click="getStaff"
                >Delete STAFF</button>
              </div>
              <div class="col-xs-12" :class="{ 'col-sm-6': editStaffFlag }">
                <button
                  type="submit"
                  class="btn-lg-green"
                  :disabled="submittingForm"
                >{{ editStaffFlag ? 'UPDATE' : 'ADD' }} STAFF</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
export default {
  name: 'StaffPopup',
  props: ['popupId', 'editStaffFlag', 'staffDetails', 'closeStaffEditPopup'],
  components: {
    TheMask
  },
  data () {
    return {
      country_isd_code: '234',
      countries: [],
      staff: {
        name: '',
        email: '',
        password: ''
      },
      imageSelected: false,
      submittingForm: false,
      isPasswordRequired: true
    }
  },
  methods: {
    getCountries () {
      this.$http
        .get('countries?service_enabled=true')
        .then(
          function (response) {
            this.countries = response.body.data
          }
        )
    },
    addStaff () {
      this.$validator.validateAll('add-staff').then((result) => {
        if (result) {
          this.submittingForm = true
          if (this.editStaffFlag) {
            this.$http
              .put('staff/' + this.staff.id, this.staff, {
                headers: {
                  'user-type': 'sadmin'
                }
              })
              .then(
                function (response) {
                  this.$emit('refreshStaffList')
                  this.$refs.popupCloseButton.click()
                  this.submittingForm = false
                  this.resetForm()
                },
                function (response) {
                  for (var key in response.body) {
                    this.$notify({ type: 'error', text: response.body[key][0] })
                    let field = this.$validator.fields.find({ name: key, scope: 'add-staff' })

                    if (field) {
                      this.$validator.errors.add({
                        id: field.id,
                        field: key,
                        msg: response.body[key][0],
                        scope: 'add-staff'
                      })
                      field.setFlags({
                        invalid: true,
                        valid: false,
                        validated: true
                      })
                    }
                  }
                  this.errorMessage = response.body.message
                  this.submittingForm = false
                }
              )
          } else {
            this.staff.country_id = this.country_isd_code
            this.$http
              .post('staff', this.staff, {
                headers: {
                  'user-type': 'sadmin'
                }
              })
              .then(
                function (response) {
                  this.$emit('refreshStaffList')
                  this.$refs.popupCloseButton.click()
                  this.submittingForm = false
                  this.resetForm()
                },
                function (response) {
                  for (var key in response.body) {
                    this.$notify({ type: 'error', text: response.body[key][0] })
                    let field = this.$validator.fields.find({ name: key, scope: 'add-staff' })

                    if (field) {
                      this.$validator.errors.add({
                        id: field.id,
                        field: key,
                        msg: response.body[key][0],
                        scope: 'add-staff'
                      })
                      field.setFlags({
                        invalid: true,
                        valid: false,
                        validated: true
                      })
                    }
                  }
                  this.errorMessage = response.body.message
                  this.submittingForm = false
                }
              )
          }
        }
      })
    },

    deleteStaff () {
      let confirmation = confirm('Are you sure you want to delete this staff?')
      if (confirmation) {
        this.$http
          .delete('staffs/' + this.staff.id, {
            headers: {
              'user-type': 'sadmin'
            }
          })
          .then(
            function (response) {
              this.$emit('refreshStaffList')
              this.$refs.popupCloseButton.click()
              this.submittingForm = false
            },
            function (response) {
              this.errorMessage = response.body.message
              this.submittingForm = false
            }
          )
      }
    },
    resetForm () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.getCountries()
      this.$nextTick()
        .then(() => {
          this.errors.clear('add-staff')
        })
    },
    getStaff () {
      this.$emit('getStaffDetails', this.staff)
    }
  },
  watch: {
    staffDetails () {
      this.staff = Object.assign({}, this.staffDetails)
      this.isPasswordRequired = !this.editStaffFlag
    },
    closeStaffEditPopup () {
      if (this.closeStaffEditPopup) {
        this.$refs.popupCloseButton.click()
      }
    }
  },
  mounted () {
    this.getCountries()
  }
}
</script>
<style scoped>
.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

.image-upload {
  display: inline-block;
  width: auto;
  text-align: center;
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
</style>
