var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "green-border-box outer-box",
      class: { grayed: _vm.staff.deleted_at }
    },
    [
      _c("div", { staticClass: "inner-box" }, [
        _c("img", {
          staticClass: "grey-logo-circle",
          attrs: { src: _vm.staff.image + "?w=150" },
          on: { error: _vm.imageLoadOnError }
        }),
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.staff.name))]),
        _c("br"),
        _c("div", { staticClass: "text-center" }, [
          _c("span", {}, [
            _vm._v(
              _vm._s(_vm.staff.deleted_at ? "Deleted On" : "Created On:") +
                "  " +
                _vm._s(_vm.staff.date) +
                " " +
                _vm._s(_vm.staff.time)
            )
          ]),
          _c("br"),
          _vm.staff.deleted_at
            ? _c(
                "button",
                {
                  staticClass: "btn-positive btn-edit",
                  attrs: {
                    type: "button",
                    "data-toggle": "modal",
                    "data-target": "#restorestorepopup"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openRestoreStorePopup(_vm.staff.id)
                    }
                  }
                },
                [_vm._v("Restore")]
              )
            : _c(
                "button",
                {
                  staticClass: "btn-positive btn-edit",
                  attrs: {
                    type: "button",
                    "data-toggle": "modal",
                    "data-target": "#editstaffpopup"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openEditStaffPopup(_vm.staff.id)
                    }
                  }
                },
                [_vm._v("EDIT")]
              )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }