





































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { Brand } from '@/components/SAdmin/BrandItem.vue'

@Component
export default class DeactivateBrandPopup extends Vue {
  @Prop() popupId!: string
  @Prop() storeDetails!: any

  restoreLocation () {
    let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
    this.$store.dispatch('restoreStore', { id: this.storeDetails.id }).then(() => {
      this.$emit('StoreRestored')
      popupCloseButton.click()
    }, () => popupCloseButton.click())
  }
}
