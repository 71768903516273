<template>
  <div class="green-border-box in-padd white-bg">
    <div class="row">
      <div class="col-xs-12 col-md-4">
        <input
          class="search-box"
          type="search"
          q="store"
          v-model="storeSearchQuery"
          placeholder="Search Staff"
        >
      </div>
      <div class="col-xs-12 col-md-8 sort-control">
        <div class="filters">
          Sort By
          <select v-model="sort">
            <option value="id-DESC" selected>Created on DESC</option>
            <option value="id-ASC">created On ASC</option>
            <option value="name-ASC">A-Z</option>
            <option value="name-DESC">Z-A</option>
            <option value="deleted_at-DESC">Deleted Stores</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-xs-12 col-sm-6 item-list"
        v-for="s in staff"
        :key="s.id"
        v-if="staff.length >0"
      >
        <StaffItem :staff="s" @getStaffDetails="getStaffDetails"/>
      </div>
    </div>
    <h2
      v-if="staff.length==0 && !loadingStaff"
      style="text-align:center;"
    >No Staff Found in system!</h2>
    <h2 v-if="loadingStaff" style="text-align:center;">Loading Staff....</h2>
    <div class="textgetStaffDetails-center" v-if="hasMoreStaff && !loadingStaff" @click="getStaff">
      <button class="btn-positive">Load more</button>
    </div>
    <StaffPopup
      @refreshStaffList="reFilter"
      :editStaffFlag="false"
      popupId="staffpopup"
      :closeStaffEditPopup="closeStaffEditPopup"
    />
    <StaffPopup
      @refreshStaffList="reFilter"
      :staffDetails="staffDetails"
      :editStaffFlag="true"
      popupId="editstaffpopup"
      :closeStaffEditPopup="closeStaffEditPopup"
    />
    <!-- <RestoreStorePopup
      @StoreRestored="reFilter"
      :storeDetails="storeDetails"
      popupId="restorestorepopup"
    /> -->
    <DeleteStaffPopup
      :staff="staff"
      @StaffDeleted="staffDeleted"
      :storeDetails="staffDetails"
      popupId="deletestaffpopup"
    />
  </div>
</template>

<script>
import StaffItem from '@/components/SAdmin/StaffItem.vue'
import StaffPopup from '@/components/SAdmin/StaffPopup.vue'
import RestoreStorePopup from '@/components/SAdmin/RestoreStorePopup'
import DeleteStaffPopup from '@/components/SAdmin/DeleteStaffPopup'
export default {
  name: 'Stores',
  data () {
    return {
      timer: 0,
      loadingStaff: true,
      hasMoreStaff: false,
      editStaffFlag: false,
      storeSearchQuery: '',
      city: '',
      zipCode: '',
      sort: 'id-DESC',
      rowsOffset: 0,
      rowsLimitPerRequest: 100,
      staff: [],
      staffDetails: {
        name: '',
        email: '',
        password: ''
      },
      closeStaffEditPopup: false
    }
  },
  components: {
    StaffItem,
    StaffPopup,
    RestoreStorePopup,
    DeleteStaffPopup
  },
  mounted () {
    this.getStaff()
  },
  methods: {
    getStaff () {
      this.loadingStaff = true
      let q = { name: this.storeSearchQuery, sort: this.sort, limit: this.rowsLimitPerRequest, offset: this.rowsOffset }
      this.$http
        .get('staff', { params: q })
        .then(
          function (response) {
            if (this.rowsLimitPerRequest === q.limit && this.rowsOffset === q.offset && this.storeSearchQuery === q.name) {
              this.rowsOffset += this.rowsLimitPerRequest
              this.staff.push(...response.body.data)
              if (this.staff.length === response.body.count) {
                this.hasMoreStaff = false
              } else {
                this.hasMoreStaff = true
              }
              this.loadingStaff = false
            }
          }
        )
    },
    staffDeleted () {
      this.closeStaffEditPopup = true
      this.reFilter()
    },
    reFilter () {
      this.staff = []
      this.rowsOffset = 0
      this.getStaff()
    },
    getStaffDetails (staffId) {
      let staffDetails = this.staff.filter(obj => {
        return obj.id === staffId
      })
      this.editStaffFlag = true
      this.staffDetails = staffDetails[0]
    }
  },

  watch: {
    sort () {
      this.loadingStaff = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    storeSearchQuery () {
      this.loadingStaff = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    city () {
      this.loadingStaff = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    },
    zipCode () {
      this.loadingStaff = true
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    }
  }
}
</script>

<style scoped>
.sort-control {
  text-align: right;
  padding: 6px 15px;
  margin-bottom: 20px;
}

.sort-control .btn-select {
  margin-bottom: 7px;
}

.filter-control {
  margin-right: 30px;
}

.filters {
  display: inline-block;
  width: auto;
  vertical-align: text-top;
}

@media only screen and (max-width: 991px) {
  .sort-control {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .filter-control {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .filter-control {
    margin-right: 0;
    width: 100%;
    margin-bottom: 8px;
    display: inline-block;
  }

  .item-list >>> .outer-box span {
    white-space: normal;
    word-wrap: break-word;
  }
}
</style>
