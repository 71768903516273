var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { autocomplete: "off", "data-vv-scope": "add-staff" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addStaff($event)
                  }
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.staff.name,
                      expression: "staff.name"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  class: {
                    input: true,
                    "is-danger": _vm.errors.has("add-staff.name")
                  },
                  attrs: {
                    type: "text",
                    name: "name",
                    placeholder: "Staff Name"
                  },
                  domProps: { value: _vm.staff.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.staff, "name", $event.target.value)
                    }
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.staff.email,
                      expression: "staff.email"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  class: {
                    input: true,
                    "is-danger": _vm.errors.has("add-staff.email")
                  },
                  attrs: {
                    type: "email",
                    name: "email",
                    placeholder: "Staff Email"
                  },
                  domProps: { value: _vm.staff.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.staff, "email", $event.target.value)
                    }
                  }
                }),
                !_vm.editStaffFlag
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-xs-6 col-sm-6" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.country_isd_code,
                                expression: "country_isd_code"
                              }
                            ],
                            class: {
                              input: true,
                              "is-danger": _vm.errors.has(
                                "add-staff.country_id"
                              )
                            },
                            attrs: { name: "country_id" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.country_isd_code = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          _vm._l(_vm.countries, function(country) {
                            return _c(
                              "option",
                              {
                                key: country.id,
                                domProps: { value: country.id }
                              },
                              [
                                _vm._v(
                                  _vm._s(country.isd_code) +
                                    "(" +
                                    _vm._s(country.name) +
                                    ")"
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-sm-6" },
                        [
                          _c("the-mask", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: true,
                                  regex: /^\d{3} ?\d{3}-?\d{4}$/
                                },
                                expression:
                                  "{ required: true, regex: /^\\d{3} ?\\d{3}-?\\d{4}$/ }"
                              }
                            ],
                            class: {
                              input: true,
                              "is-danger": _vm.errors.has("add-staff.mobile")
                            },
                            attrs: {
                              mask: "### ###-####",
                              type: "tel",
                              autocomplete: "mobile",
                              placeholder: "Mobile Number",
                              name: "mobile"
                            },
                            model: {
                              value: _vm.staff.mobile_number,
                              callback: function($$v) {
                                _vm.$set(_vm.staff, "mobile_number", $$v)
                              },
                              expression: "staff.mobile_number"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.staff.password,
                      expression: "staff.password"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { required: this.isPasswordRequired, min: 8 },
                      expression: "{required: this.isPasswordRequired, min: 8}"
                    }
                  ],
                  class: {
                    input: true,
                    "is-danger": _vm.errors.has("add-staff.password")
                  },
                  attrs: {
                    type: "password",
                    name: "password",
                    placeholder: "Password"
                  },
                  domProps: { value: _vm.staff.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.staff, "password", $event.target.value)
                    }
                  }
                }),
                _c("div", { staticClass: "row" }, [
                  _vm.editStaffFlag
                    ? _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn-lg-grey",
                            attrs: {
                              type: "button",
                              "data-toggle": "modal",
                              "data-target": "#deletestaffpopup"
                            },
                            on: { click: _vm.getStaff }
                          },
                          [_vm._v("Delete STAFF")]
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "col-xs-12",
                      class: { "col-sm-6": _vm.editStaffFlag }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn-lg-green",
                          attrs: {
                            type: "submit",
                            disabled: _vm.submittingForm
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.editStaffFlag ? "UPDATE" : "ADD") +
                              " STAFF"
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Staff\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }